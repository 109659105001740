import { render, staticRenderFns } from "./LingYuan.vue?vue&type=template&id=0b277814&scoped=true"
import script from "./LingYuan.vue?vue&type=script&lang=js"
export * from "./LingYuan.vue?vue&type=script&lang=js"
import style0 from "./LingYuan.vue?vue&type=style&index=0&id=0b277814&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b277814",
  null
  
)

export default component.exports