<template>
  <div>
    <van-action-sheet v-model="_show">
      <div class="content">
        <van-tabs v-model="activeName" @click="selectZhifufangshi">
          <van-tab title="现金支付" name="0"></van-tab>
          <van-tab title="混合支付" name="1" v-if="isPostHorse == '1'"></van-tab>
        </van-tabs>

        <div style="text-align: center;" v-if="activeName == '0'">
          <span style="font-size: 0.3784rem; color: #E54320;">￥</span>
          <span style="font-size: 0.6757rem; color: #E54320;">{{ price }}</span>
        </div>

        <div style="text-align: center;" v-if="activeName == '1'">
          <span style="font-size: 0.3784rem; color: #E54320;">￥</span>
          <span style="font-size: 0.6757rem; color: #E54320;">{{ price }}</span>
          <span style="font-size: 0.5405rem; color: #E54320;">+</span>
          <span style="font-size: 0.6757rem; color: #E54320;">{{ needRice }}</span>
          <span style="font-size: 0.3784rem; color: #E54320;">驿马</span>
        </div>

        <div class="paymentType">
          <div class="paymentType">
            <div class="paymentType-item border-btn" v-if="activeName == '1'">
              <span class="paymentType-item-type">驿马支付 {{ totalRiceGrains }}</span>
              <span class="paymentType-item-hongmi" v-show="(totalRiceGrains < needRice)">余额不足</span>
              <span>
              </span>
            </div>

            <div class="paymentType-item border-btn" @click="selectYve">
              <img style="width: 0.4865rem;" src="../img/yve.png" alt="">
              <span class="paymentType-item-type">余额</span>
              <span class="paymentType-item-hongmi">￥{{ yve }}</span>
              <span>
                <van-icon v-show="yveType" name="checked" color="#E54320" size="18" />
                <van-icon v-show="!yveType" name="checked" color="#b7b7b7" size="18" />
              </span>
            </div>

            <div>
              <div class="paymentType-item border-btn" v-for="x in shopJoinUser" :key="x.id"
                @click="paymentType(x.type, x.available, x.id)">
                <img v-if="x.type != '8'" style="width: 0.4865rem;" :src="x.icon" alt="">
                <span class="paymentType-item-type">{{ x.nameType }}</span>
                <span class="paymentType-item-hongmi" v-show="x.type == type">{{ zhifuYve }}</span>
                <span>
                  <van-icon v-show="x.type != type" name="checked" color="#b7b7b7" size="18" />
                  <van-icon v-show="x.type == type" name="checked" color="#E54320" size="18" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <van-button round color="#E54320" size="large" @click="fukuan">确认付款</van-button>
        </div>

        <van-icon size="20" class="ge_van-icon" name="cross" @click="close_action_sheet" />
      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>
import Loading from './Loading'
import { submit, zhifu } from '@/api/flMall'
import { fuli_yundian_pay } from '@/common'
import { Toast } from 'vant'
export default {
  components: {
    Loading
  },

  data () {
    return {
      type: '-1', // 0 2 3 权益 1 4 发起 5 普通 6 微信 7 支付宝
      yveType: false, // 余额勾选
      available: '0',
      id: '',
      loading: false,
      activeName: '0'
    }
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    price: {
      required: true
    },
    needRice: {
      required: true
    },
    isPostHorse: {
      type: String
    },
    totalRiceGrains: {
      required: true
    },
    data: {
      type: Array
    },
    isAdressBoolean: {
      type: Boolean,
      required: true
    },
    shopJoinUser: {
      type: Array,
      default: () => []
    },
    ticketId: {
      type: Object,
      required: true
    },
    payType: {
      type: String,
      required: true
    },
    yve: {
      required: true
    },
    jineDiff: {
      required: true
    }
  },

  watch: {
    _show (Boolean) {
      if (!Boolean) {
        this.yveType = false
        this.type = '-1'
      }
    }
  },

  computed: {
    _show: {
      get() {
        return this.show
      },
      set() {
        this.$parent.submitShow = false
      }
    },

    zhifuYve () {
      let str = ''
      let diffJine = this.jineDiff

      if (this.yveType) {
        if (diffJine >= 0) {
          str = ''
        } else {
          str = '￥' + Math.abs(diffJine)
        }
      }
      return str
    }
  },

  methods: {
    paymentType(type, available, id) {
      if (this.type == '-1') {
        this.type = type
      } else {
        this.type = '-1'
      }
      if (this.jineDiff >= 0) {
        this.yveType = false
      }
      this.available = available
      this.id = id
    },

    selectYve () {
      // 余额足的时候 余额现金选其一
      this.yveType = !this.yveType
      if (this.jineDiff >= 0) {
        this.type = '-1'
      }
    },

    close_action_sheet() {
      this.$parent.submitShow = false
    },

    selectZhifufangshi (payType) {
      this.$emit('selectZhifufangshi', payType)
    },

    closeLoading () {
      this.loading = false
    },

    fukuan() {
      if (this.isAdressBoolean) {
        Toast('请选择地址')
        return
      }

      if (!this.yveType && this.type == '-1') {
        Toast('请选择支付方式')
        return
      }

      if (Number(this.needRice) > Number(this.totalRiceGrains) && this.isPostHorse == '1') {
        Toast('驿马不足')
        return
      }

      this.loading = true
      const data = this.data
      const idType = {
        advertisementId: this.$route.query.advertisementId,
        advertisementType: this.$route.query.advertisementType
      }
      submit(data, this.ticketId, idType, this.payType).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$parent.submitShow = false

          // 余额足够支付 可以余额也可以支付报选其一
          if (this.jineDiff >= 0) {
            if (this.yveType) {
              // 纯余额支付
              this.zhifuType3(res.data.data.mergeOrderId.split(','))
            } else {
              if (this.type == '6') {
                // 微信
                this.weixinPay(res.data.data.mergeOrderId.split(','))
              } else if (this.type == '7') {
                // 支付宝
                this.zhifuType2(res.data.data.mergeOrderId.split(','))
              }
            }
          } else {
            // 余额不够支付
            if (this.yveType && this.type == '-1') {
              Toast('余额不足')
            } else if (!this.yveType && this.type != '-1') {
              if (this.type == '6') {
                // 微信
                this.weixinPay(res.data.data.mergeOrderId.split(','))
              } else if (this.type == '7') {
                // 支付宝
                this.zhifuType2(res.data.data.mergeOrderId.split(','))
              }
            } else if (this.yveType && this.type == '6') {
              this.weixinPay(res.data.data.mergeOrderId.split(','))
            } else if (this.yveType && this.type == '7') {
              this.zhifuType4(res.data.data.mergeOrderId.split(','))
            }
          }
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast('服务繁忙')
      })
    },

    // 金叶子支付
    zhifuType(ids) {
      this.loading = true
      let data = {
        ids,
        type: '4',
        joinId: this.id
      }
      zhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$emit('open_paySuccessBoolean')
        } else {
          this.loading = false
        }
      })
    },

    // 纯余额支付
    zhifuType3(ids) {
      this.loading = true
      let data = {
        ids,
        type: '5',
      }
      zhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$emit('open_paySuccessBoolean')
        } else {
          this.loading = false
        }
      })
    },

    // 微信支付
    weixinPay(ids) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.wechatPay.postMessage(`ids=${JSON.stringify(ids)}&type=1&joinId=&requesetType=13&session=${this.$route.query.session}`)
      }
      if (isAndroid) {
        window.android.wxpay(`ids=${JSON.stringify(ids)}&type=1&requesetType=13&session=${this.$route.query.session}`)
      }
    },

    // 支付宝支付
    zhifuType2(ids) {
      this.loading = true
      let data = {
        ids,
        type: '2',
      }
      zhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          fuli_yundian_pay(res.data, '2')
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast('支付失败')
      })
    },

    // 支付宝支付和余额一起支付
    zhifuType4(ids) {
      this.loading = true
      let data = {
        ids,
        type: '5',
      }
      zhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          fuli_yundian_pay(res.data, '2')
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast('支付失败')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0.8108rem 0.4054rem;
  min-height: 6.7568rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 0.3243rem 0;

      &-type {
        flex: 1;
        font-size: 0.3784rem;
        color: #333;
        margin-left: 0.2703rem;
      }

      &-hongmi {
        color: #E54320;
        font-size: 0.3784rem;
        margin-right: 0.2703rem;
      }
    }

    .border-btn {
      border-bottom: 1px solid #F6F6F6;
    }
  }

  .ge_van-icon {
    position: absolute;
    top: 0.4054rem;
    right: 0.4054rem;
  }
}

.footer {
  .van-button--round {
    height: 40px;
  }
}</style>